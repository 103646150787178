import {actions} from '/shared/actions-service'

import {copyText} from '/env-actions'

export const shareProfileService = actions.registerAction<{
    from: 'full-profile-service',
    data: { profileServiceID: string }
}, any>({
    name: 'share-profile-service',
    details: ({data}) => ({
        sharedProfileServiceId: data.profileServiceID,
    }),
    act: ({data: {profileServiceID}}) => copyText(`${location.origin}/service/${profileServiceID}`),
})
