import {computed} from 'mobx'

import {PaginatedCollection} from '/shared/collections'

import {LikeParentType, PageInfoFragment, ShortUserInfoFragment} from '/api-types'
import {client} from '/client'
import {tr} from '/locales'
import {Frame, NavElements} from '/nav-service'

import {loadCommentLikes, loadRequestLikes, loadReviewLikes} from './ops'

type UsersInfo = ShortUserInfoFragment

type LikesData = {
    pageInfo: PageInfoFragment | null
    items: UsersInfo[]
}

async function loadItemsAfter(parentId: string, parentType: LikeParentType, after: string | null) {
    let res
    if (parentType === LikeParentType.REVIEW) {
        res = await client.send(loadReviewLikes, {parentId, after})
    } else if (parentType === LikeParentType.REQUEST) {
        res = await client.send(loadRequestLikes, {parentId, after})
    } else {
        res = await client.send(loadCommentLikes, {parentId, after})
        if (res.__typename !== 'RegularMessage') {
            return {
                pageInfo: null,
                items: [],
            }
        }
    }

    if (res.likes_info === null)
        return {
            pageInfo: null,
            items: [],
        }

    return {
        pageInfo: res.likes_info.users.pageInfo,
        items: res.likes_info.users.edges.map(e => e.node),
    }
}

export class LikesList extends PaginatedCollection<UsersInfo> implements Frame {
    static description = {
        name: 'likes-list',
        needConnection: 'load',
    } as const

    static load = ({parent}: { parent: { id: string, parentType: LikeParentType } }) =>
        loadItemsAfter(parent.id, parent.parentType, null).then(likes => ({parent, likes}))

    readonly navElements: NavElements

    parentId: string
    parentType: LikeParentType
    likes: LikesData

    @computed get offline() {
        return !client.online
    }

    constructor({parent, likes}: { parent: { id: string, parentType: LikeParentType }, likes: LikesData }) {
        super()
        this.parentId = parent.id
        this.parentType = parent.parentType
        this.likes = likes
        this.navElements = {
            header: {
                title: tr.gen.t378736575,
            },
        }
    }

    protected async loadItemsAfter(after: string | null) {
        if (!after)
            return this.likes
        return loadItemsAfter(this.parentId, this.parentType, after)
    }
}
