/* eslint-disable */
import * as React from "react";
import { Atoms, atoms } from "/ui-kit/atoms.css";
import { icon } from "/ui-kit/styles.css";
export const SvgLikeColored = ({
  size = 24,
  width = size,
  height = size,
  color,
  className = "",
  ...rest
}: {
  size?: number,
  color?: Atoms["color"],
} & React.SVGProps<SVGSVGElement>) => {
  const props = {
    ...rest,
    width,
    height,
    className: `${icon} ${className} ${
      color
        ? atoms({
            color,
          })
        : ""
    }`,
  };
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.48 6.975v-2.7c0-.537-.216-1.052-.602-1.432a2.075 2.075 0 0 0-1.455-.593L5.679 8.325v7.425h7.737c.331.004.652-.11.904-.321.252-.211.418-.505.468-.827l.947-6.075a1.33 1.33 0 0 0-.322-1.088 1.37 1.37 0 0 0-1.05-.464H10.48Zm-4.8 8.775H3.621c-.364 0-.713-.142-.97-.396a1.34 1.34 0 0 1-.402-.954V9.675c0-.358.145-.701.402-.955.257-.253.606-.395.97-.395h2.057"
        fill="#FFDC94"
      />
      <path
        d="M5.68 15.75H3.621c-.364 0-.713-.142-.97-.396a1.34 1.34 0 0 1-.402-.954V9.675c0-.358.145-.701.402-.955.257-.253.606-.395.97-.395h2.057m4.802-1.35v-2.7c0-.537-.217-1.052-.603-1.432a2.075 2.075 0 0 0-1.455-.593L5.679 8.325v7.425h7.737c.331.004.652-.11.904-.321.252-.211.418-.505.468-.827l.947-6.075a1.33 1.33 0 0 0-.322-1.088 1.37 1.37 0 0 0-1.05-.464H10.48Z"
        stroke="#FFA92D"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
