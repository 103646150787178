import {observable} from 'mobx'

import {Disposable} from '/shared/dispose'
import {signal} from '/shared/signal'

import {Share} from '/ui-kit/Icons'

import {ProfileServiceWithReviewsFragment} from '/api-types'
import {client} from '/client'
import {Frame, NavElements} from '/nav-service'
import {loadProfileService} from '/profile-services/ops'
import {shareProfileService} from '/profile-services/share'
import {User} from '/ui-helpers/user-info'
import {getUserFootNote} from '/ui-helpers/user-info/user'


type Params = {
    service: ProfileServiceWithReviewsFragment
}

class FullService extends Disposable implements Frame {
    static description = {
        name: 'full-service',
    } as const

    static async loadClass({profileServiceID}: {profileServiceID: string}) {
        const { profileService } = await client.send(loadProfileService, {id: profileServiceID})
        return new FullService({
            service: profileService,
        })
    }

    reload = signal(this)

    @observable
    service: ProfileServiceWithReviewsFragment
    readonly navElements: NavElements

    get url() {
        return `service/${this.service.id}`
    }

    constructor(public readonly props: Params) {
        super()
        this.service = props.service
        this.navElements = {
            header: {
                title: User.getName(props.service.author),
                image: props.service.author.user_image,
                footNote: getUserFootNote(props.service.author),
                disableBack: true,
                rate: props.service.author.user_score.mean_grade,
                primary: true,
                actions: [
                    {
                        icon: Share,
                        act: () => shareProfileService.act({
                            from: 'full-profile-service',
                            data: {profileServiceID: this.service.id},
                        }),
                    },
                ],
            },
        }
    }
}

export {FullService}
