import {observer} from 'mobx-react'
import React from 'react'

import {Box} from '/ui-kit/Box'
import {Like, LikeColored} from '/ui-kit/Icons'
import {Line} from '/ui-kit/Line'

import {LikeParentType, LikesInfoFragment, ShortUserInfoFragment} from '/api-types'
import {vibrate} from '/env-actions'
import {openLikesList} from '/likes/likes-list'
import {tr} from '/locales'
import {AvatarImage} from '/ui-helpers/image-file'

import {setLikeAction} from './actions'

export const LikesBox = observer(({likesInfo, parentType, size = 'medium', placeholder, trusts, onSetLike, onOpenLikesList}: {
    likesInfo: LikesInfoFragment
    parentType: LikeParentType
    trusts?: ShortUserInfoFragment[]
    size?: 'medium' | 'small'
    placeholder?: boolean
    onSetLike?: () => void
    onOpenLikesList?: () => void
}) => {
    const setLike = onSetLike ? onSetLike : async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation()
        vibrate(10)
        if (likesInfo.is_like)
            likesInfo.count--
        else
            likesInfo.count++
        likesInfo.is_like = !likesInfo.is_like

        const newLikesState = await setLikeAction.act({
            from: 'request-card', data: {
                parentID: likesInfo.parent_id,
                like: likesInfo.is_like,
                parentType,
            },
        })

        likesInfo.is_like = newLikesState.is_like
        likesInfo.count = newLikesState.count
    }
    const onClickLikesCount = onOpenLikesList ? onOpenLikesList : likesInfo.count > 0 ? () => {
        void openLikesList.act({
            from: mapLikeParentType(parentType),
            parent: {
                id: likesInfo.parent_id,
                parentType,
            },
        })
    } : undefined
    const iconSize = size === 'medium' ? 24 : 19
    const likesInfoCountStr = likesInfo.count.toString()
    const likesInfoWidth = 9
    return (
        <Box display="flex">
            <Box onClick={setLike}>
                {likesInfo.is_like ? <LikeColored size={iconSize}/> : <Like color="neutral/900" size={iconSize}/>}
            </Box>
            <Box width="s8"/>
            <Box position="relative">
                    <Box
                        position="absolute"
                        display="flex"
                        wrap="noWrap"
                        alignItems="center"
                        onClick={onClickLikesCount}
                    >
                        <Box style={{
                                maxWidth: `${(likesInfoWidth * likesInfoCountStr.length).toString()}px`,
                                minWidth: `${(likesInfoWidth * likesInfoCountStr.length).toString()}px`,
                                width: `${(likesInfoWidth * likesInfoCountStr.length).toString()}px`,
                        }}>
                            <Line
                                role={size === 'medium' ? '14/24' : 'caption'}
                                weight={600}
                                color="neutral/900"
                                text={likesInfoCountStr}
                            />
                        </Box>
                        <Box width="s10"/>
                        {trusts && trusts.map((trust, i) =>
                            <div key={trust.id} style={{marginLeft: i > 0 ? '-3px' : '', zIndex: 10 - i}}>
                                <AvatarImage avatar={trust.user_image} size={20} bColor="white"/>
                            </div>
                        )}
                    </Box>
            </Box>
        </Box>
    )
})

const mapLikeParentType = (parentType: LikeParentType):
    'comment'
    | 'review'
    | 'request' => {
    if (parentType === LikeParentType.REVIEW)
        return 'review'
    if (parentType === LikeParentType.REQUEST)
        return 'request'
    return 'comment'
}
