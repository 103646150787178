import Linkify from 'linkify-react'
import {Opts} from 'linkifyjs'
import React, {ReactNode} from 'react'

export type Props = {
    children: ReactNode
    decodeUrl?: boolean
    handleLocalUrl?: boolean
}

export function openLocalUrl(e: React.MouseEvent<HTMLAnchorElement>) {
    const {href} = e.currentTarget

    const u = new URL(href)
    if (u.origin !== location.origin)
        return

    e.preventDefault()

    window.dispatchEvent(new CustomEvent('goto', {detail: u.pathname}))
}

export const Liemfy = ({
  children,
  decodeUrl = false,
  handleLocalUrl = false,
}: Props) => {
    const options: Opts = {
        target: {url: '_blank'},
        attributes: {
            rel: 'noopener nofollow noreferrer',
            onClick: handleLocalUrl ? openLocalUrl : undefined,
        },
    }
    if (decodeUrl)
        options.format = {url: decodeURIComponent}

    return (
        <Linkify options={options}>{children}</Linkify>
    )
}
