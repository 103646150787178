/* eslint-disable */
import * as React from "react";
import { Atoms, atoms } from "/ui-kit/atoms.css";
import { icon } from "/ui-kit/styles.css";
export const SvgLike = ({
  size = 24,
  width = size,
  height = size,
  color,
  className = "",
  ...rest
}: {
  size?: number,
  color?: Atoms["color"],
} & React.SVGProps<SVGSVGElement>) => {
  const props = {
    ...rest,
    width,
    height,
    className: `${icon} ${className} ${
      color
        ? atoms({
            color,
          })
        : ""
    }`,
  };
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.573 21H4.829c-.485 0-.95-.19-1.293-.527A1.786 1.786 0 0 1 3 19.2v-6.3c0-.477.193-.935.536-1.273a1.844 1.844 0 0 1 1.293-.527h2.744m6.401-1.8V5.7c0-.716-.289-1.403-.803-1.91A2.766 2.766 0 0 0 11.23 3l-3.658 8.1V21h10.315c.441.005.87-.147 1.205-.428.337-.282.558-.673.624-1.102l1.262-8.1a1.772 1.772 0 0 0-.428-1.451 1.854 1.854 0 0 0-1.4-.619h-5.177Z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
