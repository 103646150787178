import {actions} from '/shared/actions-service'

import {LikeParentType, LikesInfoFragment} from '/api-types'
import {client} from '/client'

import {setLike} from './ops'

export const setLikeAction = actions.registerAction<{
    from: 'request-card' | 'review-card' | 'comment',
    data: {parentID: string, like: boolean, parentType: LikeParentType}
}, LikesInfoFragment>({
    name: 'set-like',
    act: async ({data}) => {
        const result = await client.send(setLike, {
            like: data.like,
            parent_id: data.parentID,
            parent_type: data.parentType,
        })
        if (result.__typename === 'SetLike_LikeNotFoundError')
            throw new Error('Like was not found')
        if (result.__typename === 'SetLike_ParentNotFoundError') {
            throw new Error('Parent not found')
        }
        return result.likes_info
    },
})