import {observer} from 'mobx-react'
import React from 'react'

import {DoublePaginatedCollection, PaginatedCollection} from '/shared/collections'

import {Box} from '/ui-kit/Box'
import {Spinner} from '/ui-kit/Spinner'

import {VisibleObserver} from '/ui-elements/VisibleObserver'

function checkModel(model: PaginatedCollection<any> | DoublePaginatedCollection<any>): DoublePaginatedCollection<any> {
    if (model instanceof DoublePaginatedCollection)
        return model
    throw new Error('Model is not double paginated')
}

@observer
export class CollectionLoader extends React.Component<{
    model: PaginatedCollection<any> | DoublePaginatedCollection<any>
    disableSpinner?: boolean
    mode?: 'after' | 'before'
    placement?: 'bottom' | 'top' | 'in-place'
}>{
    get mode() {
        return this.props.mode ?? 'after'
    }

    get hasNext() {
        const {model} = this.props
        return this.mode === 'after' ? model.hasAfter : checkModel(model).hasBefore
    }

    loadNext() {
        const {model} = this.props
        if (this.mode === 'after')
            void model.loadAfter()
        else
            void checkModel(model).loadBefore()
    }

    componentDidMount() {
        const {model} = this.props
        if (model.size === 0 && this.hasNext && !model.loading)
            this.loadNext()
    }

    render() {
        const {model, disableSpinner = false, placement = 'bottom'} = this.props
        if (model.loading) return disableSpinner ? null : (
            <Box py="s16" alignSelf="stretch" width="100%" display="flex" justifyContent="center">
                <Spinner size={20} />
            </Box>
        )
        const place = placement === 'in-place' ? 'vertical-in-place' : placement
        return this.hasNext && !model.loading && (
            <VisibleObserver
                placement={place}
                size="100px"
                onVisible={() => this.loadNext()}
            />
        )
    }
}
