import React from 'react'

export class VisibleObserver extends React.Component<{
    placement: 'top' | 'bottom' | 'left' | 'right' | 'vertical-in-place'
    size: string
    threshold: number
    onVisible?: () => void
    onInvisible?: () => void
    onVisibilityChanged?: (visible: boolean) => void
}> {
    static defaultProps = {
        threshold: 0,
    }

    private ref = React.createRef<HTMLDivElement>()
    private observer!: IntersectionObserver

    componentDidMount() {
        const node = this.ref.current
        if (!node || !node.parentElement)
            return
        const parentPosition = getComputedStyle(node.parentElement).position
        if (parentPosition !== 'absolute' && parentPosition !== 'relative')
            console.warn('Render observer parent should be absolute or relative positioned!')
        this.observer = new IntersectionObserver(this.checkIntersection, {threshold: this.props.threshold})
        this.observer.observe(node)
    }

    componentWillUnmount() {
        if (this.ref.current)
            this.observer.unobserve(this.ref.current)
    }

    checkIntersection = (entries: IntersectionObserverEntry[]) => {
        const {onVisible, onInvisible, onVisibilityChanged, threshold} = this.props
        const visible = entries[0].intersectionRatio > threshold
        onVisibilityChanged && onVisibilityChanged(visible)
        if (visible)
            onVisible && onVisible()
        else
            onInvisible && onInvisible()
    }

    render() {
        const {placement, size} = this.props
        const verticalAxis = placement === 'top' || placement === 'bottom' || placement === 'vertical-in-place'
        const place = placement === 'vertical-in-place' ? 'left' : placement
        return (
            <div
                ref={this.ref}
                style={{
                    position: 'absolute',
                    height: verticalAxis ? size : '100%',
                    width: verticalAxis ? '100%' : size,
                    pointerEvents: 'none',
                    [place]: 0,
                }}
            />
        )
    }
}
