import {actions} from '/shared/actions-service'

import {nav} from '/nav-service'

const reviewsList = nav.registerLazyFrameClass( {
    name: 'reviews-list',
    lazyLoad: () => import('./lazy'),
})

type From = 'fast-reviews' | 'fast-reviews-also' | 'fast-reviewed' | 'fast-reviewed-also' | 'link' | 'fast-reviews-users' | 'review' | 'request' | 'profile' | 'profile-service'
type Mode = 'target' | 'author' | 'profile-service'

export const openReviewsList = actions.registerAction<{
    from: From,
    user: {id: string},
    profileService?: {id: string},
    mode: Mode
}>({
    name: 'open-reviews-list',
    act: ({user, profileService, mode}) => {
        reviewsList.load({user, profileService, mode})
    },
})
