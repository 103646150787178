import {actions} from '/shared/actions-service'
import {isIos} from '/shared/env'

const openStore = actions.registerAction<{
    from: 'profile-review' | 'review-list' | 'profile-footer',
}>({
    name: 'open-store',
    act: () => {
        if (isIos) {
            window.open('https://apps.apple.com/ru/app/people-do/id1372019874')
        } else {
            window.open('https://play.google.com/store/apps/details?id=net.ppldo.android')
        }
    },
})

export default openStore
