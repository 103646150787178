/* eslint-disable */
import * as React from "react";
import { Atoms, atoms } from "/ui-kit/atoms.css";
import { icon } from "/ui-kit/styles.css";
export const SvgRateInactive = ({
  size = 24,
  width = size,
  height = size,
  color,
  className = "",
  ...rest
}: {
  size?: number,
  color?: Atoms["color"],
} & React.SVGProps<SVGSVGElement>) => {
  const props = {
    ...rest,
    width,
    height,
    className: `${icon} ${className} ${
      color
        ? atoms({
            color,
          })
        : ""
    }`,
  };
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m12.459 1.804 1.937 3.9c.176.356.479.678.805.921.325.242.721.442 1.119.503l3.5.59h.001c1.036.178 1.505.61 1.634 1.012.129.401 0 1.026-.746 1.777v.001l-2.729 2.761v.001c-.302.304-.516.725-.637 1.146-.121.422-.164.897-.068 1.325l.778 3.413c.299 1.303.041 1.954-.277 2.188-.315.232-1.006.284-2.148-.394l-3.286-1.965-.003-.001c-.395-.233-.88-.335-1.339-.335-.46 0-.944.102-1.34.335l-3.3 1.95h-.002c-1.143.68-1.834.628-2.15.395-.317-.234-.574-.885-.276-2.187l.777-3.412c.098-.423.054-.896-.067-1.316-.12-.42-.334-.843-.632-1.152l-.005-.005-2.714-2.733c-.745-.75-.875-1.38-.746-1.783s.597-.835 1.631-1.005h.002l3.506-.592h.004c.39-.069.783-.265 1.109-.506.326-.24.63-.56.807-.917l1.937-3.9v-.002C10.028.827 10.589.501 11.002.5c.413-.001.973.32 1.458 1.304Z"
        fill="#E8E8E8"
        stroke="#BCC0BF"
      />
    </svg>
  );
};
