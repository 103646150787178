/* eslint-disable */
import * as React from "react";
import { Atoms, atoms } from "/ui-kit/atoms.css";
import { icon } from "/ui-kit/styles.css";
export const SvgTag = ({
  size = 24,
  width = size,
  height = size,
  color,
  className = "",
  ...rest
}: {
  size?: number,
  color?: Atoms["color"],
} & React.SVGProps<SVGSVGElement>) => {
  const props = {
    ...rest,
    width,
    height,
    className: `${icon} ${className} ${
      color
        ? atoms({
            color,
          })
        : ""
    }`,
  };
  return (
    <svg
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m2.605 10.326 2.83 2.954a2.9 2.9 0 0 0 4.22 0l2.743-2.863c1.163-1.213 1.163-3.183 0-4.402L9.561 3.067a2.903 2.903 0 0 0-2.25-.907l-3.125.157c-1.25.059-2.244 1.096-2.306 2.393l-.15 3.261c-.038.88.28 1.735.875 2.355Z"
        fill="currentColor"
      />
      <path
        d="M5.938 8.175c.862 0 1.562-.73 1.562-1.63 0-.901-.7-1.63-1.563-1.63-.862 0-1.562.729-1.562 1.63 0 .9.7 1.63 1.563 1.63Z"
        fill="currentColor"
        stroke="#fff"
        strokeLinecap="round"
      />
    </svg>
  );
};
