/* eslint-disable */
import type * as Types from '../api-types/types';

import type { Operation as DocumentNode } from '/api-types/operation';
import { LikesInfoFragmentDoc } from '../api-types/ops';
export type setLikeMutationVariables = Types.Exact<{
  like: Types.Scalars['Boolean'];
  parent_id: Types.Scalars['ID'];
  parent_type: Types.LikeParentType;
}>;


export type setLikeMutation = { data: { __typename: 'SetLike_LikeNotFoundError' } | { __typename: 'SetLike_OK', likes_info: { is_like: boolean, parent_id: string, count: number } } | { __typename: 'SetLike_ParentNotFoundError' } };


export const setLike: DocumentNode<setLikeMutation, setLikeMutationVariables> = {op: "mutation", name: "setLike", query: `mutation setLike($like:Boolean!,$parent_id:ID!,$parent_type:LikeParentType!){data:setLike(like:$like,parent_id:$parent_id,parent_type:$parent_type){__typename ...on SetLike_OK{likes_info{...LikesInfo}}}} ${LikesInfoFragmentDoc}`};