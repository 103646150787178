/* eslint-disable */
import * as React from "react";
import { Atoms, atoms } from "/ui-kit/atoms.css";
import { icon } from "/ui-kit/styles.css";
export const SvgBubbleStarOutline = ({
  size = 24,
  width = size,
  height = size,
  color,
  className = "",
  ...rest
}: {
  size?: number,
  color?: Atoms["color"],
} & React.SVGProps<SVGSVGElement>) => {
  const props = {
    ...rest,
    width,
    height,
    className: `${icon} ${className} ${
      color
        ? atoms({
            color,
          })
        : ""
    }`,
  };
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.5 4a2 2 0 0 1 2-2h17a2 2 0 0 1 2 2v11.556a2 2 0 0 1-2 2h-3.395a2 2 0 0 0-1.446.617l-2.214 2.315a2 2 0 0 1-2.89 0L8.34 18.173a2 2 0 0 0-1.446-.617H3.5a2 2 0 0 1-2-2V4Z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m12 12.7 1.94 1.228c.355.224.79-.108.697-.529l-.515-2.308 1.716-1.556c.313-.284.145-.822-.267-.856l-2.257-.2-.884-2.182a.46.46 0 0 0-.86 0l-.884 2.177-2.257.2c-.412.035-.58.573-.267.856l1.716 1.556-.514 2.308c-.094.421.34.754.696.529L12 12.7Z"
        fill="currentColor"
      />
    </svg>
  );
};
