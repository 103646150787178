import React from 'react'

import {Box} from '/ui-kit/Box'
import {Line} from '/ui-kit/Line'

export const Counter: React.FC<{
    text: string | number
}> = ({text}) =>
    <Box
        background="neutral/75"
        borderRadius="br24"
        display="flex"
        placeItems="center"
        px="s20"
        py="s8"
    >
        <Line
            role="14/18"
            color="neutral/900"
            text={String(text)}
            overflow="grow"
            weight="semiBold"
        />
    </Box>
