import {DraggableSyntheticListeners} from '@dnd-kit/core'
import {throttle} from 'lodash-es'
import React from 'react'

import {screenMode} from '/shared/screen-mode'

import {Atoms, space} from '/ui-kit/atoms.css'
import {Box, BoxProps} from '/ui-kit/Box'
import {Button} from '/ui-kit/Button'
import {Gap} from '/ui-kit/Gap'
import {Glyph, IconType} from '/ui-kit/Glyph'
import {ArrowDownOutline, ArrowLeft, ArrowRight, ArrowTopOutline, CloseSmall} from '/ui-kit/Icons'
import {Line} from '/ui-kit/Line'

import {tr} from '/locales'
import {disableScrollbars} from '/ui-elements/Scrollable'
import {IconAction} from '/ui-helpers/action'
import {AvatarImage, AvatarImageWithPlaceholder} from '/ui-helpers/image-file'

import {Counter} from './Counter'

const scrollBy = throttle((div: HTMLDivElement | null, left: number, top: number) => {
    div?.scrollBy({left, top, behavior: 'smooth'})
}, 500, {leading: true})

function useRerender() {
    const [_, set] = React.useState(0)
    return () => setTimeout(() => set(a => a + 1), 600)
}

const Scrolls: React.FC<{
    scroll?: HTMLDivElement | null
    gap: number
}> = ({scroll, gap}) => {
    const rerender = useRerender()
    if (!scroll)
        return null
    return <>
        <ArrowLeft
            color={scroll.scrollLeft === 0 ? 'neutral/300' : 'neutral/500'}
            onClick={() => {
                scrollBy(scroll, - (scroll.clientWidth + gap), 0)
                rerender()
            }}/>
        <Box ml="s8"/>
        <ArrowRight
            color={scroll.scrollLeft === scroll.scrollWidth - scroll.clientWidth ? 'neutral/300' : 'neutral/500'}
            onClick={() => {
                scrollBy(scroll, scroll.clientWidth + gap, 0)
                rerender()
            }}
        />
    </>
}

const DetailHeader: React.FC<{
    title: string
    counter?: number
    onAll?: () => void
    avatar?: AvatarImageWithPlaceholder
    iconAction?: IconAction
    scrolls?: JSX.Element
    button?: JSX.Element
}> = ({title, counter, onAll, avatar, scrolls, button, iconAction}) => (
    <Box style={{minWidth: 0}} display="flex" alignItems="center">
        {!!avatar && (<>
            <AvatarImage avatar={avatar} size={36} bColor={undefined} />
            <Box ml="s8"/>
        </>)}
        <Line role="h6" text={title}/>
        <Box ml="s8"/>
        {!!counter && <Counter text={counter.toString()}/>}
        {scrolls && <>
            <Box ml="s16"/>
            {scrolls}
        </>}
        <Box flex={1}/>
        {button}
        {onAll && <>
            <Gap size="s28" />
            <All text={tr.buttons.all} onClick={() => onAll()}/>
        </>}
        {iconAction &&
            <Box
                flexShrink={0}
                display="flex"
                placeItems="center"
                onClick={iconAction.act ?? undefined}
                cursor="pointer"
                color="neutral/600"
            >
                <Glyph size={18} icon={iconAction.icon}/>
            </Box>}
    </Box>
)

export const DetailBlockVertical = <T,>({
                                    title, counter, onAll, items = [], render, alsoText, renderEmpty, onEdit, avatar, button, cardP, renderAll, subtitle,
                                    iconAction, headerButton, compressedCount = 3,
                                }: {
    title: string
    subtitle?: string
    counter?: number
    onAll?: () => void
    items?: T[]
    render?: (item: T, index: number) => JSX.Element
    alsoText?: string
    renderEmpty?: () => JSX.Element
    renderAll?: (items: T[]) => JSX.Element
    iconAction?: IconAction
    onEdit?: () => void
    avatar?: AvatarImageWithPlaceholder
    cardP?: Atoms['p']
    headerButton?: JSX.Element
    button?: {
        text: string
        act?: () => void
    }
    compressedCount?: number
}) => {
    const [scroll, setScroll] = React.useState<HTMLDivElement | null>(null)
    const [expanded, setExpanded] = React.useState<boolean>(false)

    if (!items.length && !renderEmpty)
        return null

    return <>
        <Gap size="s32"/>
        <DetailHeader
            avatar={avatar}
            title={title}
            onAll={items.length ? onAll : undefined}
            counter={counter}
            iconAction={iconAction}
            scrolls={items.length > 1 && !screenMode.compact ? <Scrolls gap={8} scroll={scroll} /> : undefined}
            button={headerButton}
        />
        {subtitle && <>
            <Gap size="s8"/>
            <Line
                role="body2"
                color="text/secondary"
                text={subtitle}
                overflow="wrap"
            />
        </>}
        <Gap size="s12"/>
        <Box
            display="flex"
            flexDirection="column"
        >
            {items.slice(0, expanded ? items.length : compressedCount).map((item, i) => <React.Fragment key={i}>
                {i !== 0 && <Gap size="s8"/>}
                <Card
                    onClick={onEdit}
                    p={cardP}
                    roundTop={i === 0}
                    roundBottom={i === items.length - 1}
                    flat={i !== 0 && i !== items.length - 1}
                >{render?.(item, i)}</Card>
            </React.Fragment>)}
        </Box>
        {counter !== undefined && counter > compressedCount && (
            <Box
                display="flex"
                wrap="nowrap"
                alignItems="center"
                position="relative"
                onClick={() => setExpanded((prevExpanded) => !prevExpanded)}
            >
                {!expanded && <Box display="flex" position="absolute" style={{
                    height: '50px',
                    minHeight: '50px',
                    maxHeight: '50px',
                    width: '100%',
                    minWidth: '100%',
                    top: '-50px',
                    backgroundImage: 'linear-gradient(to bottom, transparent, white)',
                }}/>}
                <Box display="flex" flex={1} style={{height: '1px', minHeight: '1px', maxHeight: '1px'}} background="neutral/300"></Box>
                {expanded
                    ? <Box><ArrowTopOutline color="neutral/400"/></Box>
                    : <Box><ArrowDownOutline color="neutral/400"/></Box>
                }
                <Box display="flex" flex={1} style={{height: '1px', minHeight: '1px', maxHeight: '1px'}} background="neutral/300"></Box>
            </Box>
        )}
        {/*<HorizontalScroll negativeMargin={screenMode.compact ? 's20' : 's0'} scrollRef={s => setScroll(s)}>*/}
        {/*    {!renderAll && items.map((item, i) => <React.Fragment key={i}>*/}
        {/*        {i !== 0 && <Gap size="s8"/>}*/}
        {/*        <Card fullWidth={items.length === 1} onClick={onEdit} p={cardP}>{render?.(item, i)}</Card>*/}
        {/*    </React.Fragment>)}*/}
        {/*    {renderAll?.(items)}*/}
        {/*    {alsoText != '' && !!counter && onAll && counter > items.length && <>*/}
        {/*        <Gap size="s8"/>*/}
        {/*        <Also*/}
        {/*            text={alsoText}*/}
        {/*            act={onAll}*/}
        {/*            count={counter - items.length}*/}
        {/*        />*/}
        {/*    </>}*/}
        {/*    {!items.length && renderEmpty?.()}*/}
        {/*</HorizontalScroll>*/}
        {!!items.length && button && <>
            <Gap size="s12"/>
            <Button look="primary" text={button.text} disabled={!button.act} onClick={() => button.act?.()}/>
        </>}
    </>

}

export const DetailBlock = <T,>({
    title, counter, onAll, items = [], render, alsoText, renderEmpty, onEdit, avatar, button, cardP, renderAll, subtitle,
    iconAction, headerButton,
}: {
    title: string
    subtitle?: string
    counter?: number
    onAll?: () => void
    items?: T[]
    render?: (item: T, index: number) => JSX.Element
    alsoText?: string
    renderEmpty?: () => JSX.Element
    renderAll?: (items: T[]) => JSX.Element
    iconAction?: IconAction
    onEdit?: () => void
    avatar?: AvatarImageWithPlaceholder
    cardP?: Atoms['p']
    headerButton?: JSX.Element
    button?: {
        text: string
        act?: () => void
    }
}) => {
    const [scroll, setScroll] = React.useState<HTMLDivElement | null>(null)

    if (!items.length && !renderEmpty)
        return null

    return <>
        <Gap size="s32"/>
        <DetailHeader
            avatar={avatar}
            title={title}
            onAll={items.length ? onAll : undefined}
            counter={counter}
            iconAction={iconAction}
            scrolls={items.length > 1 && !screenMode.compact ? <Scrolls gap={8} scroll={scroll} /> : undefined}
            button={headerButton}
        />
        {subtitle && <>
            <Gap size="s8"/>
            <Line
                role="body2"
                color="text/secondary"
                text={subtitle}
                overflow="wrap"
            />
        </>}
        <Gap size="s12"/>
        <HorizontalScroll negativeMargin={screenMode.compact ? 's20' : 's0'} scrollRef={s => setScroll(s)}>
            {!renderAll && items.map((item, i) => <React.Fragment key={i}>
                {i !== 0 && <Gap size="s8"/>}
                <Card fullWidth onClick={onEdit} p={cardP}>{render?.(item, i)}</Card>
            </React.Fragment>)}
            {renderAll?.(items)}
            {alsoText != '' && !!counter && onAll && counter > items.length && <>
                <Gap size="s8"/>
                <Also
                    text={alsoText}
                    act={onAll}
                    count={counter - items.length}
                />
            </>}
            {!items.length && renderEmpty?.()}
        </HorizontalScroll>
        {!!items.length && button && <>
            <Gap size="s12"/>
            <Button look="primary" text={button.text} disabled={!button.act} onClick={() => button.act?.()}/>
        </>}
    </>

}

const HorizontalScroll: React.FC<{
    negativeMargin: keyof typeof space
    scrollRef?: React.Ref<HTMLDivElement>
}> = ({children, negativeMargin, scrollRef}) => {
    const m = `-${space[negativeMargin]}`
    return (
        <Box
            ref={scrollRef}
            display="flex"
            className={disableScrollbars}
            style={{
                overflowX: screenMode.compact ? 'scroll' : 'hidden',
                marginLeft: m,
                marginRight: m,
            }}
        >
            <Gap size={negativeMargin}/>
            {children}
            <Gap size={negativeMargin}/>
        </Box>
    )
}

export const Also = ({count, act, text = tr.profile.also, actionText = tr.profile.alsoAll}: {
    text?: string
    count?: number
    act: () => void
    actionText?: string
    transparent?: boolean
}) => (
    <Card center>
        <Box display="flex" alignSelf="stretch" justifyContent="center">
            <Line role="body2" text={text} weight={400} overflow="wrap" align="center"/>
            {!!count && <>
                <Gap size="s4"/>
                <Line role="body2" text={count.toString()} weight={600} overflow="grow"/>
            </>}
        </Box>
        <Gap size="s10"/>
        <Button look="primary" size="s" text={actionText} onClick={() => act()}/>
    </Card>
)

export const Card: React.FC<{
    fullWidth?: boolean
    center?: boolean
    p?: Atoms['p']
    onClick?: () => void
    roundTop?: boolean
    roundBottom?: boolean
    flat?: boolean
}> = ({center, children, p = 's16', onClick, fullWidth, roundTop, roundBottom, flat}) => (
    <Box
        background="neutral/75"
        style={{
            flexBasis: !screenMode.compact ? '100%' : fullWidth ? 'calc(100% - 40px)' : 'calc(90% - 40px)',
            minWidth: 0,
            borderRadius: !flat ? `${roundTop || !roundTop && !roundBottom ? '16px 16px' : '0px 0px'} ${roundBottom || !roundTop && !roundBottom ? '16px 16px' : '0px 0px'}` : undefined,
        }}
        flexShrink={0}
        flexGrow={0}
        p={p}

        display="flex"
        flexDirection="column"
        placeItems={center ? 'center' : undefined}
        children={children}
        onClick={onClick}
    />
)

export const All: React.FC<{
    text: string
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}> = ({text, onClick}) =>
    <Box
        as="button"
        p="s0"
        background="misc/none"
        style={{outline: 'none'}}
        border="none"
        display="flex"
        justifyContent="center"
        alignItems="center"
        onClick={onClick}
    >
        <Line
            role="14/18"
            color="green/500"
            text={text}
            overflow="grow"
            weight={600}
        />
    </Box>

export const Tag = ({gray, text, onRemove, handle, Icon, ...props}: {
    gray?: boolean
    text: string
    onRemove?: () => void
    handle?: DraggableSyntheticListeners
    Icon?: IconType
} & BoxProps) => {
    const py = Icon ? 6 : 4
    return (
        <Box
            display="flex"
            alignItems="center"
            background={gray ? 'neutral/75' : 'neutral/0'}
            borderRadius="br16"
            maxWidth="100%"
            style={{padding: `${py}px 12px`}}
            {...props}
        >
            <div style={{display: 'flex'}} {...handle}>
                {!!Icon && <><Icon size={24} /><Box width="s8"/></>}
                <Line role="body1" weight={400} color="neutral/900" text={text} overflow="ellipsis" />
            </div>
            {onRemove && <>
                <Box
                    onClick={onRemove}
                    style={{marginLeft: '4px', marginRight: '-4px'}}
                    color="neutral/400"
                >
                    <CloseSmall/>
                </Box>
            </>}
        </Box>
    )
}

export const EmptyBlock = ({text, button, image}: {
    text: string
    button: {
        text: string
        act?: () => void
    }
    image: string
}) =>
    <Card center>
        <img src={image} style={{marginTop: '-12px'}}/>
        <Gap size="s8"/>
        <Line role="body1" weight={400} color="neutral/600" text={text} overflow="wrap" align="center"/>
        <Gap size="s16"/>
        <Box display="flex" flexDirection="column" alignSelf="stretch">
            <Button look="primary" text={button.text} disabled={!button.act} onClick={e => {
                button.act?.()
                e.stopPropagation()
            }}/>
        </Box>
    </Card>
