import {disposers, IDisposable} from '/shared/dispose'

export type Listener<Result> = (res: Result) => void

export type Unlisten = () => void

export type Listenable<Result> = {
    listen: (listener: Listener<Result>) => Unlisten
}

const Unit = null
type Unit = typeof Unit

export interface Signal extends Listenable<Unit> {
    (): void
    add: (listener: Listener<Unit>) => Signal
    result: Promise<Unit>
    fired: boolean
    dispose: () => void
    when: (...listenable: Listenable<any>[]) => Signal
}

export function signal(disposeWith: IDisposable<any>, {permanent}: {permanent: boolean} = {permanent: false}): Signal {
    let resolveResult: (res: Unit) => void
    const p: Promise<Unit> = new Promise(ok => resolveResult = ok)

    let listeners: Set<Listener<Unit>> | null = new Set()

    const dispose = disposers().add(() => {
        listeners = null
        fire.fired = true
    })

    const fire: Signal = () => {
        const copy = listeners
        if (!copy)
            return
        if (!permanent) {
            dispose()
            resolveResult?.(Unit)
        }
        for (const listener of copy)
            listener(Unit)
    }
    fire.listen = (listener) => {
        if (!listeners)
            return () => {/*ok*/}
        listeners.add(listener)
        return () => listeners?.delete(listener)
    }
    fire.add = (listener => {
        fire.listen(listener)
        return fire
    })
    fire.result = p
    fire.fired = false
    fire.dispose = dispose
    fire.when = (...listenable) => {
        for (const l of listenable)
            dispose.add(l.listen(() => fire()))
        return fire
    }

    disposeWith.dispose.add(() => dispose())

    return fire
}
