import React from 'react'

import {Box} from '/ui-kit/Box'
import {Divider} from '/ui-kit/Divider'
import {Gap} from '/ui-kit/Gap'
import {Burger, CommentsBubble, Warning, Forward} from '/ui-kit/Icons'
import {Line} from '/ui-kit/Line'

import {ShortUserInfoFragment} from '/api-types'
import {tr} from '/locales'
import {reportContent} from '/report-content'
import {fullReview} from '/reviews/full-review'
import {shareReview} from '/reviews/upsert-review'
import {bottomList} from '/ui-helpers/bottom-list'
import {AvatarImage} from '/ui-helpers/image-file'

export const ReviewCard: React.FC<{
    id: string
    content: JSX.Element
    like: JSX.Element
    comments: number
    commentTrusts?: ShortUserInfoFragment[]
    inFrame?: boolean
    background?: boolean
    onClickComments?: () => void
    from?: 'feed' | 'full-review' | 'profile'
}> = ({id, content, inFrame, like, comments, background, commentTrusts, onClickComments, from}) => (
    <Box background={background ? 'neutral/0' : undefined} display="flex" flexDirection="column">
        {content}
        <Box px="s12"><Divider /></Box>
        <Box display="flex" px="s12" py="s8" alignItems="center">
            {like}
            <Gap size="s56" />
            <Box display="flex" justifyContent="center" onClick={inFrame ? undefined : onClickComments ? onClickComments : e => {
                e.stopPropagation()
                void fullReview.act({from: 'card', id, openComments: true, commentsCount: comments})
            }}>
                <CommentsBubble color="neutral/900"/>
                <Box width="s8"/>
                <Box position="relative">
                    <Box position="absolute" display="flex" wrap="noWrap" alignItems="center">
                        <Line role="14/24" weight={600} color="neutral/900" text={comments.toString()}/>
                        <Box width="s10"/>
                        {commentTrusts && commentTrusts.map((trust, i) =>
                            <div key={trust.id} style={{marginLeft: i > 0 ? '-3px' : '', zIndex: 10 - i}}>
                                <AvatarImage avatar={trust.user_image} size={20} bColor="white"/>
                            </div>
                        )}
                    </Box>
                </Box>
                <Box width="s32" />
            </Box>
            <Box />
            {/*<Box onClick={e => {*/}
            {/*    e.stopPropagation()*/}
            {/*    bottomList.open([*/}
            {/*        {*/}
            {/*            icon: Forward,*/}
            {/*            accent: 'standard',*/}
            {/*            text: tr.gen.t667846078,*/}
            {/*            act: () => shareReview.act({from: from || 'full-review', data: {reviewID: id}}),*/}
            {/*        },*/}
            {/*        {*/}
            {/*            icon: Warning,*/}
            {/*            accent: 'danger',*/}
            {/*            text: tr.messages.contextMenu.complain,*/}
            {/*            act: () => reportContent({type: 'review', content: {id}}, {reportReviewFrom: from}),*/}
            {/*        },*/}
            {/*    ] as const)*/}
            {/*}}>*/}
            {/*    <Burger size={20} color="neutral/900"/>*/}
            {/*</Box>*/}
        </Box>
    </Box>
)
