/* eslint-disable */
import type * as Types from '../../api-types/types';

import type { Operation as DocumentNode } from '/api-types/operation';
import { PageInfoFragmentDoc, ShortUserInfoFragmentDoc, UserNameFragmentDoc, UserProfileNameFragmentDoc, UserImageFragmentDoc, UserProfileImageFragmentDoc, UserImageFileFragmentDoc, UserInfoFragmentDoc, IndustryFragmentDoc, LocationFragmentDoc, UserScoreFragmentDoc, LikesInfoFragmentDoc, UserIdFragmentDoc, RequestFragmentDoc, LikesInfoWithTrustsFragmentDoc, CommentGroupWithTrustsFragmentDoc, ReviewFragmentDoc, GradesFragmentDoc, ServiceFragmentDoc } from '../../api-types/ops';
export type LikesUsersFragment = { pageInfo: { startCursor: string | null, endCursor: string | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges: Array<{ node: { is_my: boolean, is_guaranteed: boolean, follower_state: Types.FollowerState, trust_state: Types.TrustState, id: string, user_score: { mean_grade: number | null }, user_name: { short_name: string, first_name: string, last_name: string, color: number }, user_image: { image: { id: string, key: string, url: string, area: { x: number, y: number, height: number, width: number } | null } | null, placeholder: { initials: string, color: { main_color: number, gradient_to: number } } }, user_info: { location: { emoji: string, name: string } }, user_details: { jobs: Array<{ industry: { id: string, description: string, title: string, name: string }, job: { url: string | null, job: string, company: string | null } }>, short_jobs: Array<{ job: { url: string | null, job: string, company: string | null }, industry: { id: string, description: string, title: string, name: string } }> } } | { id: string, user_score: { mean_grade: number | null }, user_name: { first_name: string, last_name: string, color: number }, user_image: { placeholder: { initials: string, color: { main_color: number, gradient_to: number } } } } }> };

export type loadReviewLikesQueryVariables = Types.Exact<{
  parentId: Types.Scalars['ID'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type loadReviewLikesQuery = { data: { likes_info: { users: { pageInfo: { startCursor: string | null, endCursor: string | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges: Array<{ node: { is_my: boolean, is_guaranteed: boolean, follower_state: Types.FollowerState, trust_state: Types.TrustState, id: string, user_score: { mean_grade: number | null }, user_name: { short_name: string, first_name: string, last_name: string, color: number }, user_image: { image: { id: string, key: string, url: string, area: { x: number, y: number, height: number, width: number } | null } | null, placeholder: { initials: string, color: { main_color: number, gradient_to: number } } }, user_info: { location: { emoji: string, name: string } }, user_details: { jobs: Array<{ industry: { id: string, description: string, title: string, name: string }, job: { url: string | null, job: string, company: string | null } }>, short_jobs: Array<{ job: { url: string | null, job: string, company: string | null }, industry: { id: string, description: string, title: string, name: string } }> } } | { id: string, user_score: { mean_grade: number | null }, user_name: { first_name: string, last_name: string, color: number }, user_image: { placeholder: { initials: string, color: { main_color: number, gradient_to: number } } } } }> } } } };

export type loadRequestLikesQueryVariables = Types.Exact<{
  parentId: Types.Scalars['ID'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type loadRequestLikesQuery = { data: { likes_info: { users: { pageInfo: { startCursor: string | null, endCursor: string | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges: Array<{ node: { is_my: boolean, is_guaranteed: boolean, follower_state: Types.FollowerState, trust_state: Types.TrustState, id: string, user_score: { mean_grade: number | null }, user_name: { short_name: string, first_name: string, last_name: string, color: number }, user_image: { image: { id: string, key: string, url: string, area: { x: number, y: number, height: number, width: number } | null } | null, placeholder: { initials: string, color: { main_color: number, gradient_to: number } } }, user_info: { location: { emoji: string, name: string } }, user_details: { jobs: Array<{ industry: { id: string, description: string, title: string, name: string }, job: { url: string | null, job: string, company: string | null } }>, short_jobs: Array<{ job: { url: string | null, job: string, company: string | null }, industry: { id: string, description: string, title: string, name: string } }> } } | { id: string, user_score: { mean_grade: number | null }, user_name: { first_name: string, last_name: string, color: number }, user_image: { placeholder: { initials: string, color: { main_color: number, gradient_to: number } } } } }> } } } };

export type loadCommentLikesQueryVariables = Types.Exact<{
  parentId: Types.Scalars['ID'];
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type loadCommentLikesQuery = { data: { __typename: 'AlbumMessage' } | { __typename: 'ForwardMessage' } | { __typename: 'NotificationMessage' } | { __typename: 'RegularMessage', likes_info: { users: { pageInfo: { startCursor: string | null, endCursor: string | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges: Array<{ node: { is_my: boolean, is_guaranteed: boolean, follower_state: Types.FollowerState, trust_state: Types.TrustState, id: string, user_score: { mean_grade: number | null }, user_name: { short_name: string, first_name: string, last_name: string, color: number }, user_image: { image: { id: string, key: string, url: string, area: { x: number, y: number, height: number, width: number } | null } | null, placeholder: { initials: string, color: { main_color: number, gradient_to: number } } }, user_info: { location: { emoji: string, name: string } }, user_details: { jobs: Array<{ industry: { id: string, description: string, title: string, name: string }, job: { url: string | null, job: string, company: string | null } }>, short_jobs: Array<{ job: { url: string | null, job: string, company: string | null }, industry: { id: string, description: string, title: string, name: string } }> } } | { id: string, user_score: { mean_grade: number | null }, user_name: { first_name: string, last_name: string, color: number }, user_image: { placeholder: { initials: string, color: { main_color: number, gradient_to: number } } } } }> } } | null } | { __typename: 'StickerMessage' } };

export const LikesUsersFragmentDoc = `fragment LikesUsers on UserConnection{pageInfo{...PageInfo}edges{node{...ShortUserInfo ...UserScore}} }`;
export const loadReviewLikes: DocumentNode<loadReviewLikesQuery, loadReviewLikesQueryVariables> = {op: "query", name: "loadReviewLikes", query: `query loadReviewLikes($parentId:ID!,$after:String){data:review(id:$parentId){likes_info{users(first:20,after:$after){...LikesUsers}}}} ${LikesUsersFragmentDoc} ${PageInfoFragmentDoc} ${ShortUserInfoFragmentDoc} ${UserNameFragmentDoc} ${UserProfileNameFragmentDoc} ${UserImageFragmentDoc} ${UserProfileImageFragmentDoc} ${UserImageFileFragmentDoc} ${UserInfoFragmentDoc} ${IndustryFragmentDoc} ${UserScoreFragmentDoc}`};
export const loadRequestLikes: DocumentNode<loadRequestLikesQuery, loadRequestLikesQueryVariables> = {op: "query", name: "loadRequestLikes", query: `query loadRequestLikes($parentId:ID!,$after:String){data:request(id:$parentId){likes_info{users(first:20,after:$after){...LikesUsers}}}} ${LikesUsersFragmentDoc} ${PageInfoFragmentDoc} ${ShortUserInfoFragmentDoc} ${UserNameFragmentDoc} ${UserProfileNameFragmentDoc} ${UserImageFragmentDoc} ${UserProfileImageFragmentDoc} ${UserImageFileFragmentDoc} ${UserInfoFragmentDoc} ${IndustryFragmentDoc} ${UserScoreFragmentDoc}`};
export const loadCommentLikes: DocumentNode<loadCommentLikesQuery, loadCommentLikesQueryVariables> = {op: "query", name: "loadCommentLikes", query: `query loadCommentLikes($parentId:ID!,$after:String){data:comment_V2(id:$parentId){__typename ...on RegularMessage{likes_info{users(first:20,after:$after){...LikesUsers}}}}}${LikesUsersFragmentDoc} ${PageInfoFragmentDoc} ${ShortUserInfoFragmentDoc} ${UserNameFragmentDoc} ${UserProfileNameFragmentDoc} ${UserImageFragmentDoc} ${UserProfileImageFragmentDoc} ${UserImageFileFragmentDoc} ${UserInfoFragmentDoc} ${IndustryFragmentDoc} ${UserScoreFragmentDoc}`};