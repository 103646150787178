import React from 'react'
import Scrollbars, {ScrollbarProps} from 'react-custom-scrollbars'

import * as S from './styles.css'

export {disableDesktopScrollbars, disableScrollbars} from './styles.css'

export interface ScrollableProps {
    className?: string

    disableKinematic?: boolean
    registerScrollable?: React.Ref<HTMLDivElement>
    scrollbarProps?: ScrollbarProps
    scrollX?: boolean
    scrollY?: boolean
    thumbClassName?: string
    trackClassName?: string
    viewHeight?: string
    id?: string

    onScroll?: React.UIEventHandler<HTMLElement>
}

export class Scrollable extends React.Component<ScrollableProps> {
    renderTrackH = (props: any) => (
        <div
            {...props}
            className={S.track + ' ' + S.htrack + ' ' + (this.props.trackClassName ?? '')}
        />
    )

    renderTrackV = (props: any) => (
        <div
            {...props}
            className={S.track + ' ' + S.vtrack + ' ' + (this.props.trackClassName ?? '')}
        />
    )

    renderThumbH = (props: any) => (
        <div
            {...props}
            className={S.thumb + ' ' + (this.props.thumbClassName ?? '')}
        />
    )

    renderThumbV = (props: any) => (
        <div
            {...props}
            className={S.thumb + ' ' + (this.props.thumbClassName ?? '')}
        />
    )

    register = (el: any) => this.registerScrollable(el ? el.view : null)

    registerScrollable = (current: HTMLDivElement | null) => {
        const {registerScrollable} = this.props
        if (!registerScrollable)
            return
        if (typeof registerScrollable === 'function')
            registerScrollable(current)
        if (typeof registerScrollable === 'object')
            Object.assign(registerScrollable, {current})
    }

    renderView = (props: any) => {
        const {scrollX, scrollY, disableKinematic, viewHeight, id} = this.props
        //scrollbars disabled via css now, should working in all major browsers even without negative margins
        const marginBottom = 0
        const marginRight = 0
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        const maxHeight = props.style.maxHeight && props.style.maxHeight + (scrollX ? 0 : props.style.marginBottom)
        return (
            <div
                id={id}
                {...props}
                className={S.disableDesktopScrollbars}
                style={{
                    ...props.style,
                    overflowX: scrollX ? 'scroll' : 'hidden',
                    marginBottom,
                    overflowY: scrollY ? 'scroll' : 'hidden',
                    marginRight,
                    maxHeight: viewHeight || maxHeight,
                    WebkitOverflowScrolling: disableKinematic ? 'auto' : 'touch',
                }}
            />
        )
    }

    render() {
        const {scrollbarProps = {}, className, children, onScroll} = this.props
        const props = {className, children}

        return (
            <Scrollbars
                className={S.root}
                renderTrackHorizontal={this.renderTrackH}
                renderTrackVertical={this.renderTrackV}
                renderThumbHorizontal={this.renderThumbH}
                renderThumbVertical={this.renderThumbV}
                renderView={this.renderView}
                ref={this.register}
                hideTracksWhenNotNeeded
                onScroll={onScroll}
                {...scrollbarProps}
            >
                <div {...props} style={{position: 'relative'}} />
            </Scrollbars>
        )
    }
}
