import React from 'react'

import { Box } from '/ui-kit/Box'
import { Rate, RateInactive } from '/ui-kit/Icons'


const starSize = {
    small: {
        size: 17,
        gap: 4,
    },
    large: {
        size: 34,
        gap: 8,
    },
}

type Size = keyof typeof starSize

const Star = ({i, value, onClick, size = 'small'}: {
    i: number
    value: number
    onClick?: (i: number) => void
    size?: Size
}) => {
    const Star = i <= Math.round(value) ? Rate : RateInactive
    return (
        <Box
            display="flex"
            placeItems="center"
            style={{marginLeft: i > 1 ? `${starSize[size].gap}px` : 0}}
        >
            <Star
                size={starSize[size].size}
                onClick={() => onClick?.(i)}
            />
        </Box>
    )
}

const max = 5

export const RatingStars = ({value, onChange, size}: {
    value: number
    onChange?: (value: number) => void
    size?: Size
}) => {
    return (
        <span style={{
            cursor: onChange ? 'pointer' : undefined,
            display: 'inline-flex',
            WebkitTapHighlightColor: 'transparent',
        }}>
            {new Array<null>(max).fill(null).map((_, i) =>
                <Star key={i} i={i + 1} value={value} onClick={onChange} size={size}/>
            )}
        </span>
    )
}
