import {format as formatUni} from 'date-fns'
import en from 'date-fns/locale/en-US'

export const format: typeof formatUni = (date, form) => {
    return formatUni(date, form, {locale: en})
}

export function time(time: string) {
    return format(new Date(time), 'HH:mm')
}

export function dotDate(date: string, withTime?: boolean) {
    return format(new Date(date), withTime ? 'dd.MM.yy HH:mm' : 'dd.MM.yyyy')
}

export function customDate(date: string, cFormat: string) {
    return format(new Date(date), cFormat)
}
