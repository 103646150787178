import React, {useState} from 'react'

type Props = {
    clamp: number
    className?: string
    children: React.ReactNode
    showMore?: JSX.Element
    forceShowMore?: boolean
    after?: JSX.Element
}

export const ClampLines = ({children, clamp, className, showMore, after, forceShowMore}: Props) => {
    const [sm, setShowMore] = useState<boolean | null>(forceShowMore || null)
    return <>
        <div
            ref={el => {
                if (!el || forceShowMore) return
                if (el.scrollHeight > el.clientHeight)
                    setShowMore(true)
                else
                    setShowMore(false)
            }}
            className={className}
            style={{
                WebkitLineClamp: clamp,
                WebkitBoxOrient: 'vertical',
                display: '-webkit-box',
                overflow: 'hidden',
                position: 'relative',
            }}
        >
            {children}
            {sm && showMore}
        </div>
        {sm === false && after}
    </>
}
