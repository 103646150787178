/* eslint-disable */
import * as React from "react";
import { Atoms, atoms } from "/ui-kit/atoms.css";
import { icon } from "/ui-kit/styles.css";
export const SvgCommentsBubble = ({
  size = 24,
  width = size,
  height = size,
  color,
  className = "",
  ...rest
}: {
  size?: number,
  color?: Atoms["color"],
} & React.SVGProps<SVGSVGElement>) => {
  const props = {
    ...rest,
    width,
    height,
    className: `${icon} ${className} ${
      color
        ? atoms({
            color,
          })
        : ""
    }`,
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M7.486 10.29h9.028M7.486 13h5.417M6.132 5.776h11.736a2.257 2.257 0 0 1 2.257 2.257v7.222a2.257 2.257 0 0 1-2.257 2.257h-6.77L7.5 20.66a.009.009 0 0 1-.015-.007v-3.14H6.132a2.257 2.257 0 0 1-2.257-2.257V8.033a2.257 2.257 0 0 1 2.257-2.257Z"
        stroke="currentColor"
        strokeWidth={1.7}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
