import React from 'react'

import {Box} from '/ui-kit/Box'
import {Gap} from '/ui-kit/Gap'
import {Tag} from '/ui-kit/Icons'
import {Line} from '/ui-kit/Line'

import {ProfileServiceFragment, ProfileServicePaymentType} from '/api-types'
import {tr} from '/locales'


export const ProfileServicePrice: React.FC<{
    service: ProfileServiceFragment
}> = ({service}) => {
    return (
        <Box display="flex" alignItems="center">
            <Tag color="green/500" size={18} />
            <Gap size="s4" />
            <Line
                text={textByPaymentType(service)}
                role="body2"
                weight="bold"
                color="green/500"
                overflow="grow"
            />
        </Box>
    )
}

const textByPaymentType = (service: ProfileServiceFragment): string => {
    switch (service.paymentType) {
        case ProfileServicePaymentType.FROM_AND_TO:
            return `${service.price_V2.currency.symbol}${service.price_V2.value} - ${service.extraPrice.currency.symbol}${service.extraPrice.value}`
        case ProfileServicePaymentType.HOURLY:
            return `${service.price_V2.currency.symbol}${service.price_V2.value}/${tr.gen.t724523452}`
        case ProfileServicePaymentType.PER_MONTH:
            return `${service.price_V2.currency.symbol}${service.price_V2.value}/${tr.gen.t948392910}`
        case ProfileServicePaymentType.FOR_THE_SERVICE:
            return `${service.price_V2.currency.symbol} ${service.price_V2.value}`
        case ProfileServicePaymentType.BY_AGREEMENT:
            return tr.gen.t943937892
        case ProfileServicePaymentType.IS_FREE:
            return tr.gen.t451367725
        default:
            return ''
    }
}
