import {observer} from 'mobx-react-lite'
import React from 'react'

import {actions} from '/shared/actions-service'

import {Box} from '/ui-kit/Box'
import {Like, LikeColored} from '/ui-kit/Icons'

import {LikeParentType} from '/api-types'
import {tr} from '/locales'
import {nav} from '/nav-service'
import {CollectionLoader} from '/ui-elements/CollectionLoader'
import {ButtonAction} from '/ui-helpers/action'
import {UserInfo} from '/ui-helpers/user-info'
import {openProfile} from '/user-profile'

import {LikesList} from './frame'

export const likesList = nav.registerFrameClass(LikesList, {
    body: {
        type: 'data',
        block: observer(({model}) => <>
            {model.all.map(m => (
                <Box
                    key={m.id}
                    display="flex"
                    alignItems="center"
                    wrap="nowrap"
                    pl="s24"
                    pr="s24"
                    pt="s8"
                    pb="s8"
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        flex={1}
                        flexShrink={1}
                        minWidth="s0"
                        pr="s16"
                    >
                        <UserInfo user={m} size={46} onClick={() => openProfile({id: m.id}, 'likes')} />
                    </Box>
                    <LikeColored size={24} />
                </Box>
            ))}
            <CollectionLoader model={model}/>
        </>),
        background: 'neutral/0',
    },
})

type From =
    'comment'
    | 'review'
    | 'request'

type Params = {
    from: From,
    parent: {
        id: string
        parentType: LikeParentType
    },
}

export const openLikesList = actions.registerAction<Params>({
    name: 'open-likes-list',
    act: ({parent}) => {
        likesList.load({parent})
    },
})
