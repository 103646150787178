import {
    IsMyFragment,
    ShortUserInfoFragment,
    UserImageFileFragment,
    UserImageFragment,
    UserNameFragment,
} from '/api-types'
import {tr} from '/locales'
import type {UserProfileDataFragment} from '/user-profile'

export type Job = {
    job: string
    company: string | null
    url: string | null
}

export function getFirstName(fragment: UserNameFragment) {
    return fragment.user_name.first_name
}

export function getLastName(fragment: UserNameFragment) {
    return fragment.user_name.last_name
}

export function getName(fragment: UserNameFragment): string {
    return `${fragment.user_name.first_name} ${fragment.user_name.last_name}`.trim()
}

export function getShortName(fragment: UserNameFragment): string | null {
    if (!('short_name' in fragment.user_name))
        return null
    return fragment.user_name.short_name
}

export function getProfileSlug(fragment: ShortUserInfoFragment): string {
    return getShortName(fragment) ?? ('user/' + fragment.id)
}

export function getProfileLink(fragment: ShortUserInfoFragment): string {
    return `${location.origin}/${getProfileSlug(fragment)}`
}

export function getUserFootNote(fragment: ShortUserInfoFragment): string {
    if ('user_details' in fragment && fragment.user_details.short_jobs.length > 0) {
        const { job } = fragment.user_details.short_jobs[0]
        return job.job + (job.company ? ' ' + tr.profile.jobs.at + ' ' + job.company : '')
    }
    return ''
}

export function getImage({user_image}: UserImageFragment): UserImageFileFragment | null {
    if ('image' in user_image && user_image.image)
        return user_image.image
    return null
}

export function isMe(t: IsMyFragment): boolean {
    return 'is_my' in t && t.is_my
}

export function isInvited(t: ShortUserInfoFragment): boolean {
    return !('user_info' in t)
}

export function isInvitedByMe(t: UserProfileDataFragment): boolean {
    return 'is_invited_by_me' in t && t.is_invited_by_me
}

function getInfo(t: ShortUserInfoFragment) {
    return 'user_info' in t ? t.user_info : null
}

export function getDetails(t: ShortUserInfoFragment) {
    return 'user_details' in t ? t.user_details : null
}

export function getJobs(t: ShortUserInfoFragment): Job[] {
    return getDetails(t)?.jobs.map(j => j.job) ?? []
}

export function getLocation(t: ShortUserInfoFragment) {
    return getInfo(t)?.location.name ?? null
}

export function jobs(t: ShortUserInfoFragment) {
    const j = getJobs(t)
    return j.map(j => j.job).join(', ')
}

export function jobsFull(t: ShortUserInfoFragment) {
    const j = getJobs(t)
    return j.map(j => j.job).join(', ')
}

export function jobAndLocation(t: ShortUserInfoFragment): string {
    const l = getLocation(t)
    return getJobs(t).slice(0, 1).map(j => j.job).concat(...(l ? [l] : [])).join(', ')
}

export function status(user: {
    invited?: boolean | null
    online?: boolean | null
    last_seen?: string | null
}): string {
    if (user.invited)
        return tr.messages.header.invited.toLowerCase()
    else if (user.online)
        return tr.messages.header.online.toLowerCase()
    else if (user.last_seen)
        return tr.messages.header.lastSeen(user.last_seen)
    else
        return tr.messages.header.neverOnline
}
